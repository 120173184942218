/* Common Imports */

import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

/* Component Imports */

import { useMediaQuery } from "@material-ui/core";
import CarouselSlideOne from "./CarouselSlideOne";
import Carousel from "@beegru/react-material-ui-carousel";

const useStyles = makeStyles((theme) => ({
  carouselContainerStyling: {
    width: "100%",
  },
  carouselHolder: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "calc(100vh - 4rem)",
  },
}));

const CarouselOne = (props) => {
  const theme = useTheme();
  const classes = useStyles();

  const [carousel, setCarousel] = useState([]);

  useEffect(() => {
    fetch('/api/home').then((r) => r.json()).then((d) => {
      setCarousel(d.carousel);
    }).catch((e) => console.error(e));
  }, []);

  return (
    <React.Fragment>
      <div className={classes.carouselHolder}>
        <Carousel
          navButtonsProps={{
            style: {
              background: "-webkit-linear-gradient(0deg, #6C4CEF, #C74CC1)",
              boxShadow:
                "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
            },
          }}
          containerStyling={classes.carouselContainerStyling}
          animation="slide"
          // navButtonsAlwaysVisible={useMediaQuery(theme.breakpoints.up("sm"))}
          // navButtonsAlwaysInvisible={useMediaQuery(theme.breakpoints.down("xs"))}
          navButtonsAlwaysInvisible={true}
          autoPlay={useMediaQuery(theme.breakpoints.up("sm"))}
          interval={15000}
        >
          {carousel.map((carouselSlide, key) => {
            return <CarouselSlideOne key={key}
              heading={carouselSlide.heading ? carouselSlide.heading : "Cloud Computing Strategies"}
              subHeading={carouselSlide.subHeading ? carouselSlide.subHeading : "Create a cloud strategy to maximize the benefits of cloud computing"}
              btnLabel={carouselSlide.btnLabel ? carouselSlide.btnLabel : "Know More"}
              btnHref={carouselSlide.btnHref ? carouselSlide.btnHref : "#"}
              illustration={carouselSlide.illustration ? carouselSlide.illustration : "/images/illustrations/index/analysis.svg"}
            />;
          })}
        </Carousel>
      </div>
    </React.Fragment>
  );
};

export default CarouselOne;
