/* Common Imports */

import React, { useState, useEffect } from "react";
import loadable from "@loadable/component";

/* Component Imports */

import Layout from "../components/layout-components/Layout";
import SectionOne from "../components/page-sections/index/SectionOne";
import AppConfig from "../AppConfig";

const SectionTwo = loadable(() =>
  import("../components/page-sections/index/SectionTwo")
);
const SectionThree = loadable(() =>
  import("../components/page-sections/index/SectionThree")
);
const SectionFour = loadable(() =>
  import("../components/page-sections/index/SectionFour")
);
const SectionFive = loadable(() =>
  import("../components/page-sections/index/SectionFive")
);

const Index = () => {
  const [sectionthree, setSectionThree] = useState([]);
  const [sectionfour, setSectionFour] = useState([]);

  useEffect(() => {
    fetch(AppConfig.apiUrlPrefix + "home")
      .then((r) => r.json())
      .then((d) => {
        setSectionThree(d.section_three);
        setSectionFour(d.section_four);
      })
      .catch((e) => console.error(e));
  }, []);

  return (
    <React.Fragment>
      <Layout>
        <SectionOne />
        <SectionTwo />
        {sectionthree.map((secthree, key) => {
          return (
            <SectionThree
              key={key}
              illustration={
                secthree.illustration
                  ? secthree.illustration
                  : "/images/illustrations/index/reports.svg"
              }
              heading={secthree.heading ? secthree.heading : "Reports"}
              subHeading={
                secthree.subHeading
                  ? secthree.subHeading
                  : "In-depth reports providing metrics saving time and money in the exchange between producers and consumers in order to streamline business operations and develop a collaborative environment that allows any organization to overperform, furthering relationships with their suppliers, customers and employees."
              }
              btnLabel={secthree.btnLabel ? secthree.btnLabel : "Know More"}
              btnHref={secthree.btnHref ? secthree.btnHref : "#"}
            />
          );
        })}
        {sectionfour.map((secfour, key) => {
          return (
            <SectionFour
              key={key}
              illustration={
                secfour.illustration
                  ? secfour.illustration
                  : "/images/illustrations/index/classes.svg"
              }
              heading={secfour.heading ? secfour.heading : "Classes"}
              subHeading={
                secfour.subHeading
                  ? secfour.subHeading
                  : "With Snap’s research, insight and guidance from industry experts you can scale your business to great heights. The workshops are aimed to improve your business capabilities and administration. We help you in indentifying and learning strategic technological trends."
              }
              btnLabel={secfour.btnLabel ? secfour.btnLabel : "Know More"}
              btnHref={secfour.btnHref ? secfour.btnHref : "#"}
            />
          );
        })}
        <SectionFive source="home-page" />
      </Layout>
    </React.Fragment>
  );
};

export default Index;
