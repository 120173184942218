/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import { Typography, Button } from "@material-ui/core";

/* Data Imports */

import AppConfig from "../../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
    },
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  headingStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    fontSize: "4.5rem",
    lineHeight: "6.75rem",
    fontWeight: 600,
    color: "#18191F",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.125rem",
      lineHeight: "3.1875rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
    },
  },
  subHeadingStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    fontSize: "1.125rem",
    lineHeight: "1.6875rem",
    fontWeight: 500,
    color: "#18191F",
    margin: "1rem 0rem 1rem 0rem",
  },
  illustrationContainer: {},
  illustrationSizer: {
    /* XXS breakpoint */
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      height: "280px",
      width: "280px",
    },
    [theme.breakpoints.only("xs")]: {
      height: "320px",
      width: "320px",
    },
    [theme.breakpoints.only("sm")]: {
      height: "352px",
      width: "352px",
    },
    [theme.breakpoints.only("md")]: {
      height: "384px",
      width: "384px",
    },
    [theme.breakpoints.only("lg")]: {
      height: "512px",
      width: "512px",
    },
    [theme.breakpoints.only("xl")]: {
      height: "512px",
      width: "512px",
    },
    /* XXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
      height: "768px",
      width: "768px",
    },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    margin: "1rem 0rem 1rem 0rem",
  },
  buttonStyle: {
    color: "#FFFFFF",
    background: "#2784C8",
    textTransform: "capitalize",
  },
}));

const CarouselSlideOne = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.sectionContainer}>
        <div className={classes.textContainer}>
          <Typography
            variant="h1"
            component="h1"
            className={classes.headingStyle}
          >
            {props.heading}
          </Typography>
          <Typography
            variant="body2"
            component="span"
            className={classes.subHeadingStyle}
          >
            {props.subHeading}
          </Typography>
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              size="large"
              href="#contact-us"
              title="Know More"
              rel="noopener"
              className={classes.buttonStyle}
            >
              {props.btnLabel}
            </Button>
          </div>
        </div>
        <div className={classes.illustrationContainer}>
          <img
            src={
              props.illustration
                ? props.illustration
                : "/images/icons/social/location.svg"
            }
            alt={AppConfig.imageAltText}
            width={512}
            height={512}
            loading="eager"
            referrerPolicy="no-referrer"
            longdesc={AppConfig.imageLongdesc}
            className={classes.illustrationSizer}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default CarouselSlideOne;
