/* Common Imports */

import React from "react";
// import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import CarouselOne from "./sub-components/CarouselOne";

// const useStyles = makeStyles((theme) => ({}));

const SectionOne = (props) => {
  //   const classes = useStyles();
  return (
    <React.Fragment>
      <CarouselOne />
    </React.Fragment>
  );
};

export default SectionOne;
